import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  data: () => ({
    landingCitiesList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    sortColumn: "title",
    sortDirection: "asc",
    webUrl: process.env.VUE_APP_WEB_URL,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      if (this.landingCitiesList.length > 0) {
        return (
          _.where(this.landingCitiesList, { isSelected: true }).length ===
          this.landingCitiesList.length
        );
      }
      return false;
    },
    hasLandingCitiesShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasLandingCitiesAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasLandingCitiesDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasLandingCitiesUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },

    hasLandingCitiesHideShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Landing City Show|Hide" }).length >
            0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Landing City Show|Hide" })
                .length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, {
                    name: "Landing City Show|Hide",
                  }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    //Sorting Data
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },

    //
    checkUncheckedList(event) {
      this.landingCitiesList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },

    //Update Landing Country Status
    updateLandingCountryStatus(landingCities, event) {
      let _vm = this;
      let _msg =
        landingCities.isDisplay == true
          ? "Are you sure you want to Deactive this Landing Country?" // Prompt for deactivation
          : "Are you sure you want to Active this Landing Country?"; // Prompt for activation
      let _isDisplay = landingCities.isDisplay == true ? 1 : 0;

      // Confirm the action with the user
      this.$dialog
        .confirm(_msg)
        .then(function () {
          // Execute if user confirms
          _vm.axios
            .post(
              "/landing-cities-update-status/id?id=" +
                landingCities.id +
                "&isDisplay=" +
                _isDisplay
            )
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById(
                "landing-city-checkbox-" + landingCities.id
              ).checked = landingCities.isDisplay;
            });
        })
        .catch(function () {
          // Execute if user cancels
          document.getElementById(
            "landing-city-checkbox-" + landingCities.id
          ).checked = landingCities.isDisplay;
        });
    },

    //Single And Multiple Data Remove
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific record is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Loop through the landingCitiesList to find selected records
        this.landingCitiesList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Send a POST request to delete the records
      _vm.axios
        .post("/landing-cities-deletes", { ids: ids })
        .then(function (response) {
          // Refresh the data after successful deletion
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    // Search Value On List Table
    search(value) {
      this.getAll();
    },

    //Search || Pagination || Sorting Function
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    //Get All Data Landing Citites
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Make a GET request to retrieve landing cities data
      this.axios
        .get("/landing-cities" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          // Set "isSelected" property to false for each landing city
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.landingCitiesList = data;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {
          // Handle any errors that occur during the request
        });
    },

    //Check And Uncheck
    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Update selectedRows with checkbox IDs for all items in landingCitiesList
          this.selectedRows = this.landingCitiesList.map(
            (item) => "checkbox" + item.id
          );
          // Set isSelected property of all landingCitiesList elements to true
          this.landingCitiesList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Clear selectedRows
          this.selectedRows = [];
          // Set isSelected property of all landingCitiesList elements to false
          this.landingCitiesList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the target ID to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the target ID from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
